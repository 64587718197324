$(function(){

  /****************************************
   * トップ(top page)
   ****************************************/

  /****************************************
   * swiper
   ****************************************/
  (function () {
    // #js-slide--keyvisua
    const swiper_keyvisual = new Swiper('#js-slide--keyvisual', {
      pagination: '.swiper-pagination',
      paginationClickable: true,
      loop: true,
      autoplay: 3000,
      touchRatio: 0.6,
      speed: 1000,
      effect: 'fade',
      fade: {
        crossFade: true
      },
    });

    // #js-slide--campaign
    const swiper_campaign = new Swiper('#js-slide--campaign', {
      pagination: '.swiper-pagination',
      slidesPerView: 3,
      paginationClickable: true,
      spaceBetween: 30,
      touchRatio: 0.6,
      autoplay: 3000,
      loop: true,
      //centeredSlides : true,
      speed: 1000,
      breakpoints: {
        767: {
          speed: 1000,
          autoplay: 3000,
          loop: true,
          slidesPerView: 1,
          spaceBetween: 0,
          effect: 'fade',
          fade: {
            crossFade: true
          },
        }
      }
    });

  }).call(this);


});